async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');
    return Flickity;
}

importFlickity().then((Flickity) => {
    const carousels = document.querySelectorAll('.carousel');
    if (carousels) {
        carousels.forEach(carousel => {
            new Flickity(carousel, {
                lazyLoad: 1,
                cellAlign: 'center',
                adaptiveHeight: true,
                freeScroll: true,
                draggable: '>1',
                wrapAround: true,
                pageDots: false,
                setGallerySize: false
            });
        });
    }
});